.filters {
  transition: all .5s ease-out;
  @include media-breakpoint-down(sm) {
    background-color: #fff;
    display: none;
    height: 100%;
    left: 0;
    pointer-events: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 110;
    opacity: 0.5;
  }
  &.show {
    @include media-breakpoint-down(sm) {
      display: block;
      //top: 300px;
      opacity: 1;
    }
  }
}

.filters-toggle {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.filter-content {
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 54px;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    width: 100%;
  }
}

.filter-header {
  display: block;
  position: absolute;
  padding: $navbar-padding-y $navbar-padding-x;
  background: $gray-lighter;
  top: 0;
  left: 0;
  right: 0;
  @include media-breakpoint-up(md) {
    display: none;
  }
  .btn {
    position: absolute;
    right: $navbar-padding-x;

  }

}
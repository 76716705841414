.home {
   h2, h3 {
      margin-top: $headings-margin-top / 2;
   }
   img {
      max-width: 100%;
   }

}
.hero {
   margin-top: -$spacer-y;
   background-size: cover;
   background-position: center;
}
.hero-content {
   @include gradient-y(rgba(white, 0.33), white);
   @include media-breakpoint-up(md) {
      @include gradient-x-three-colors(rgba(white, 1), rgba(white, 0.33), 70%, transparent);
   }
}
.hero-content, .feature {
   padding: 60px 0;
}
.hero, .hero-img {
   min-height: 375px;
}
.hero-img {
   position: relative;
   margin-top: 60px;
   img {
      position: absolute;
      top: 0;
      z-index: -1;
   }
}
.hero-img img, .feature-img {
	filter: drop-shadow(0 5px 10px rgba(0,0,0,.15));
}
@include media-breakpoint-up(md) {
   .hero-img {
      margin-top: 0;
      img {
         top: -10px;
         left: -20px;
      }
   }
}
@include media-breakpoint-up(lg) {
   .hero, .hero-img {
      min-height: 450px;
   }
}
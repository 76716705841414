.site-header {
  box-shadow: 0 0 15px rgba(black, 0.35);
}

.row-header {
  flex-direction: column-reverse;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.brand {
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 5px;
  @include media-breakpoint-up(md) {
    padding: 15px;
    padding-left: $navbar-padding-x;
  }
  img {
    //height: 60px;
    //max-width: 100%;
    //height: auto;
    max-height: 74px;
  }
}

//.brand-ofm {
//  position: relative;
//  top: -2px;
//  img {
//    height: 50px;
//    @include media-breakpoint-up(md) {
//      height: auto;
//    }
//  }
//}
.brand-sdsoy {
  position: relative;
  top: -2px;
  //img {
  //  height: 60px;
  //  @include media-breakpoint-up(md) {
  //    height: auto;
  //  }
  //}
}

.nav-aux {
  text-align: right;
  height: 100%;
  font-size: 1.25rem;
  a, span {
    margin-top: 1.5rem;
    display: inline-block;
    //padding: 1.5rem 0.5rem;
  }
  span {
  font-size: 1rem;
  }
  a {
    //color: $brand-gray-medium;

  }
}

.navbar-primary {
  margin-top: 0.85rem;
  font-size: $font-size-lg;

  .navbar-brand {
    width: 0;
    overflow: hidden;
    margin: 0;
  }
}

.banner {
  z-index: 100;
  //margin-bottom: $spacer-y;
  position: relative;
}

.bg-faded {
  background-color: $gray-lighter;
}

.badge-userinfo {
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 1.125rem;
  font-weight: 200;
}
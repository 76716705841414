/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 12, 2017 */



@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-bold-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}




@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-bolditalic-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;

}




@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-extrabold-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-extrabold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;

}




@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-extrabolditalic-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-extrabolditalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;

}




@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-italic-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;

}




@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-light-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}



@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-lightitalic-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-lightitalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;

}




@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-regular-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}




@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-semibold-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}




@font-face {
  font-family: 'open_sans';
  src: url('#{$font-path}/opensans-semibolditalic-webfont.woff2') format('woff2'),
  url('#{$font-path}/opensans-semibolditalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;

}
// Grid settings
$enable-flex: true;
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Colors
$brand-gray: #b4b4b4;
$brand-gray-dark: #343434;
$brand-gray-medium: lighten($brand-gray-dark, 50%);
$brand-green: #229a61;
$brand-green-light: #7bc143;
$brand-blue: #002f87;
$brand-blue-dark: #00345c;
$brand-blue-light: #1d6fcb;

$brand-orange: #e8a219;
$brand-teal: #4899b7;
$brand-olive: #71953d;
$brand-tan: #edf0c5;
$brand-seafoam: #8fba9f;

$brand-primary: $brand-orange;
$body-color: $brand-gray-dark;

$btn-primary-bg: $brand-olive;

$btn-secondary-color:            $brand-olive;
$btn-secondary-border:           $brand-olive;

$btn-secondary-color:            white;
$btn-secondary-bg:               $brand-blue-dark;
$btn-secondary-border:           $brand-blue-dark;



// Type
$font-size-root: 14px;
$font-size-h1: 2.57rem;
$font-size-h2: 2.14rem;
$font-size-h3: 1.71rem;
$font-size-h4: 1.43rem;
$font-size-h5: 1rem;
$font-size-h6: 1rem;

$headings-margin-top: 2rem;


$card-spacer-x: 1rem;

$font-path:          "../fonts/" !default;
$icon-font-path:          "../fonts/" !default;
$icon-font-name:          "glyphicons-halflings-regular" !default;

$navbar-inverse-color:                 rgba(white,.95);

$navbar-padding-y: 0.3rem;
$nav-link-padding: 0.25em 1.25em;

$brand-inverse: $brand-blue-dark;

$font-family-sans-serif: 'open_sans', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: $headings-margin-top / 3;
}

h2 {
  color: $brand-teal;
}

.entry-title {
  margin-bottom: $spacer * 2;
}

.page-header h1 {
  color: $brand-teal;
}
.reports {
  margin-top: $spacer-y;
  position: relative;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  margin-left: -($grid-gutter-width-base / 4);


  .stat {
    padding: ($grid-gutter-width-base / 4) ($grid-gutter-width-base / 4);
    //width: 33.3333%;

    .stat-body {
      ////@include gradient-y($body-bg, lighten($brand-gray, 20%));
      //border: 1px solid $brand-blue-light;
      ////color: white;
      //padding: ($grid-gutter-width-base / 4) ($grid-gutter-width-base / 3);
      //border-radius: $border-radius;
    }
    flex: 0 1 100%;

    @include media-breakpoint-up(sm) {
      flex: 0 1 50%;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 1 33.3333333%;
    }
    @include media-breakpoint-up(xl) {
      flex: 0 1 25%;
    }

    //@media (min-width: 1300px) {
    //  flex: 0 1 16.6666667%;
    //}
    //@media (min-width: 1500px) {
    //  flex: 0 1 14.285714286%;
    //}
    .stat-label {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      color: $brand-olive;

    }
    .stat-value {
      font-size: 1.3rem;
      font-weight: 200;
    }
    .stat-unit {
      font-size: 1.125rem;
    }
  }
}

.updated p {
  @extend .alert;
  @extend .alert-success;
}
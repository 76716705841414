.card-title {
  margin-top: 0;
}

.card {
  margin-bottom: $grid-gutter-width-base;
  //background: $brand-teal;
}

.card-title {
  font-size: $font-size-h4;
}
.accordion {
  .card {
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    border-bottom: 1px solid $gray-lighter;
  }
  .card-block {
    font-size: 0.85rem;
    padding-top: 0.125rem;
  }
  .card-header {
    background: transparent;

    padding: 0;
    border-bottom: none;
    a {
      display: block;
      position: relative;
      padding: ($card-spacer-y) $card-spacer-x;
      color: $brand-blue;
      font-weight: bold;
      &:hover,
      &:active {
        text-decoration: none;
      }
      &:after {
        content: "–";
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        font-size: 1.25rem;
      }
    }
    a.collapsed {
      &:after {
        content: "+";
      }
    }
  }
  .collapse {
  }
}
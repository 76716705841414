
body .acf-field {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  .select2-choice {
    @extend .form-control;
  }
  .select2-choice {
    height: auto;
  }
  &.acf-field-select {
    select {
      @extend .form-control;

    }

  }
  .acf-input-append {
    padding: $input-padding-y $input-padding-x;
    height: auto;
  }
}

body .acf-input-wrap input {
  height: auto;
}

body {
  .gform_wrapper {
    .input-opt-in {
      .gfield_label {
        display: none;
      }
    }
  }
  .gfield_checkbox li {
    margin-bottom: 0;
    input {
      margin-top: 0 !important; // because gravity forms is dumb
    }
  }
  .ginput_container input[type="checkbox"], .ginput_container input[type="radio"] {
    display: inline-block;
    width: auto;
    height: auto;
    margin-right: 4px;
    border-top: none;
    @include box-shadow(none);
  }

  .ginput_container .gfield_checkbox label, .ginput_container .gfield_radio label {
    font-weight: normal;
  }
}

.acf-form-fields {
  display: flex;
  flex-wrap: wrap;
}

.acf-form-submit {
  input[type='submit'] {
    @extend .btn;
    @extend .btn-primary;
  }
}

.acf-field--post-title {
  flex-basis: 100%;
  
}
.acf-field {
  //flex-basis: 100%;
}

.acf-tab-wrap {
  flex-basis: 100%;
}

.acf-label {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  color: $brand-olive;
}

.form-login {
  label {
    display: block;
  }
  input[type="text"],
  input[type="password"] {
    @extend .form-control;
  }
  input[type="submit"] {
    @extend .btn;
    @extend .btn-primary;
  }

}

a.btn.btn-primary {
  &:focus {
    color: $btn-primary-color;
  }
}
a.btn.btn-secondary {
  &:focus {
    color: $btn-secondary-color;
  }
}
.site-footer {
  background: $brand-blue-dark;
  margin-top: 2rem;
  padding-bottom: 2rem;
  color: white;
  .footer-icon {
    margin-right: 10px;
    color: white;
    font-size: 2em;
    text-align: center;
  }
  .footer-block {
    display: flex;
    text-align: left;
    line-height: 1.125rem;
  }
  .logos {
    padding: 2rem 0;
  }
  .logo {
    padding: 1rem;
  }
  .logo-sdsu {
    position: relative;
    top: -0.5rem;
  }
}

.site-footer-inner {
  padding-top: 1rem;
}
.filters {
  //display: flex;
  flex-wrap: wrap;
  //border-right: 1px solid $brand-gray;
  .filter {
    flex: 0 1 100%;
    label {
      display: flex;
      width: 100%;
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      color: $brand-olive;
    }
  }
  .facetwp-type-number_range,
  .facetwp-type-date_range {
    label {
      width: 40%;
      display: none;
    }
    input {
      width: 40%;
    }
  }
  .flatpickr-alt,
  .facetwp-number-min {
    margin-right: 5%;

  }

  .facetwp-facet {
    margin-bottom: $spacer-y / 2;
  }
  .facetwp-type-fselect {
    .fs-wrap {
      width: 100%;
    }
  }
  .facetwp-type-slider {
    margin-top: 9px;
    .noUi-horizontal .noUi-handle {
      top: -9px;
      height: 24px;
    }

    .facetwp-slider-wrap {
      padding-bottom: 5px;
      .facetwp-slider {
        height: 9px;
      }
      .noUi-connect {
        background: $brand-blue-light;
      }
    }

    .facetwp-slider-label {
      display: block;
      text-align: center;
      font-size: $font-size-base * 0.875;
    }
  }

}

.facetwp-facet {
  transition: all .5s ease-out;
  position: relative;
  margin-bottom: 40px;


}

.facetwp-facet.loading {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background: rgba(white, 0.25);
  }

}

.facetwp-type-county_map {
  margin-top: 0.7rem;
  height: 450px;
  background: $gray-lighter;
  .facet-map-embed {
    height: 100%;
  }
}

.facetwp-checkbox {
  background: url(../images/checkbox.png) 0 50% no-repeat;
  background-size: 14px 14px;
  margin-bottom: 4px;
  padding-left: 20px;
  cursor: pointer;
}


.facetwp-selections {
  //margin-top: $spacer;
  //margin-bottom: $spacer;
  position: absolute;
  bottom: 100%;
  ul {
    padding: 0;
    margin: 0;
    li {
      background: $gray-lighter;
      padding: $btn-padding-y-sm $btn-padding-x-sm;
      font-size: 0.9rem;

      //&:after {
      //  @extend .glyphicon;
      //  content:"\e014";
      //  background: none;
      //  width: auto;
      //  height: auto;
      //  position: relative;
      //  top: 2px;
      //  margin-left: 8px;
      //}
    }
  }
  .facetwp-selection-value {
    font-weight: 700;
  }
}

.filter-reset {
  padding: ($card-spacer-y) $card-spacer-x;
  @include media-breakpoint-up(md) {
    padding: $card-spacer-y 0;
  }

}

.facetwp-pager {
  color: white;
  .facetwp-page {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-border-radius-sm);
  }
  .dots {
    margin-right: 6px;
    color: $body-color;
  }
}
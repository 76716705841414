.img-primary {
  display: block;
  margin-bottom: $spacer;
}

.gallery {
  margin-left: -($spacer / 2);

  >a {
    display: inline-block;
    margin: $spacer / 2;
  }
}